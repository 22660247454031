import Script from 'next/script';

import DynamicComponent from './DynamicComponent';

type Blok = {
  _uid?: string;
  jsElId?: string;
  children?: any;
  cssClass?: string;
  customCss?: string;
  customHtml?: string;
  customJS?: string;
};

type Props = {
  blok: Blok;
};

const CustomCodeWrapper: React.FC<Props> = ({
  blok: { _uid, jsElId, children, cssClass, customCss, customHtml, customJS }
}) => {
  const cleanHtml = (customHtml) => {
    return {
      __html: `${customHtml}`
    };
  };

  const createStyles = () => {
    return {
      __html: `${customCss}`
    };
  };

  const createJS = (customJS) => {
    return (
      <Script
        id={_uid}
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `${customJS}`
        }}
      />
    );
  };

  return (
    <div className={cssClass}>
      <style dangerouslySetInnerHTML={createStyles()} />
      {customHtml && <div dangerouslySetInnerHTML={cleanHtml(customHtml)} />}
      {customJS && (
        <>
          {createJS(customJS)}
          <div id={jsElId} />
        </>
      )}
      {children.map((blok) => (
        <DynamicComponent blok={blok} key={blok._uid} />
      ))}
    </div>
  );
};

export default CustomCodeWrapper;
